/* --------------------------------------------------------------
    Import CSS Dependencies
-------------------------------------------------------------- */
@import 'colors';
@import 'icons';
@import 'fonts';
@import 'default';
@import 'nav';
@import 'buttons';
/* --------------------------------------------------------------
    Start Project CSS
-------------------------------------------------------------- */
html {
  position: relative;
  height: 100%;
  background-color: #fff;
  // overflow-y: scroll;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
}
body {
  height: 100%;
  font-family: var(--font-family);
  text-rendering: optimizeLegibility;
  color: #444;
}
.wrap {
  padding: 30px 0;
}
.container-fluid.but-fixed {
  max-width: 1356px;
}
@media (min-width: 1400px) {
  .container-fluid.but-fixed {
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
  }
}
.footer {
  padding-top: 20px;
  padding-bottom: 15px;
  width: 100%;
  background-color: #f5f5f5;
  color: #555;
  border-top: 1px solid #e4e4e4;
  font-size: 0.9rem;
  p {
    margin: 0 0 5px 0;
  }
  p.trustmark {
    display: none;
    margin: 0;
  }
  a {
    color: #555;
  }
  .social a {
    border: 0;
  }
  img {
    display: inline-block;
  }
  #trustwaveSealImage {
    margin: 0 10px 30px 0;
  }
}

#footer-info {
  border-bottom: 1px solid #ccc;
}

/* --------- asc styles --------- */

#header {
  background: rgba(255, 255, 255, 0.8);
  a.logo {
    border: 0;
    width: 70%;
    max-width: 375px;
    margin-top: 12px;
    margin-bottom: 12px;
    img {
      margin-right: 0;
      margin-bottom: 7px;
      width: 100%;
      height: auto;
      max-width: 335px;
    }
    span.small {
      display: block;
      color: #555;
      line-height: 1em;
      margin-top: 0;
      font-size: 14px;
      font-family: var(--font-family);
      font-weight: normal;
    }
  }
  #support {
    p {
      margin: 2px 12px 0 0;
      strong {
        font-size: 16px;
      }
    }
    a.login {
      padding: 0 12px 2px 12px;
      color: #fff;
      background: $theme-primary;
      text-decoration: none;
      border-radius: 0 0 4px 4px;
      border: 0;
      &:before {
        display: inline-block;
        margin-right: 5px;
        width: inherit;
        height: inherit;
        content: '\e828';
        color: inherit;
        font-family: 'icons';
        font-weight: normal;
        text-align: center;
        font-size: 105%;
        vertical-align: top;
      }
      &:hover {
        background: lighten($theme-primary, 7.5%);
      }
    }
    span.phone {
      padding-right: 8px;
      border-right: 1px dotted #333;
    }
    a.livechat {
      color: #555;
      border: 0;
      &:before {
        display: inline-block;
        margin-right: 5px;
        width: inherit;
        height: inherit;
        content: '\e830';
        color: inherit;
        font-family: 'icons';
        font-weight: normal;
        text-align: center;
        font-size: 105%;
        vertical-align: top;
      }
      &:hover:before {
        color: $theme-primary;
      }
    }
  }
  #search {
    float: right;
    clear: right;
    margin: 12px 0;
    input[type='search'] {
      width: 130px;
      transition: all 0.5s cubic-bezier(0, 0.7, 0, 1);
      &:focus {
        width: 250px;
        background-color: #fff;
      }
      &::placeholder {
        color: #999 !important;
      }
    }
  }
  input.addsearch {
    background: #f2f2f2
      url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 24 24'%3E%3Cg fill='none' stroke='gray' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2'%3E%3Cpath d='M10 3a7 7 0 107 7 7 7 0 00-7-7zM21 21l-6-6' vector-effect='non-scaling-stroke'%3E%3C/path%3E%3C/g%3E%3C/svg%3E")
      no-repeat 9px center;
    padding-left: 35px;
  }
}
#nav {
  form {
    padding: 9px 20px;
    label {
      color: #fff;
    }
    input {
      margin-bottom: 5px;
      border: 0;
    }
  }
}

// Banner Wrap / Banners
#banner-wrap {
  &.landing {
    // landing used for all major redesigned banners
    background: #4c62b7 url('../images/banners/banner-bg-sm.jpg') no-repeat 50% 50%;
    background-size: cover;
    #banner {
      h2 {
        text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.75);
      }
      .details {
        font-size: 1em;
        text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.75);
      }
    }
  }
  &.boating {
    background: #4c62b7 url('../images/banners/boating-banner-bg-sm.jpg') no-repeat 50% 50%;
    background-size: cover;
  }
  &.defensive {
    background: #4c62b7 url('../images/banners/defensive-banner-bg-sm.jpg') no-repeat 50% 50%;
    background-size: cover;
  }
  &.bus {
    background: #4c62b7 url('../images/banners/bus-banner-bg-sm.jpg') no-repeat 50% 50%;
    background-size: cover;
  }
  &.drivered {
    background: #4c62b7 url('../images/banners/drivered-banner-bg-sm.jpg') no-repeat 50% 50%;
    background-size: cover;
  }
  &.driving-records {
    #banner {
      background: #fff url('../images/banners/banner-bg-dr-sm.jpg') no-repeat 0 bottom;
      background-size: contain;
    }
  }
  &.hazmat {
    background: #4c62b7 url('../images/banners/hazmat-banner-bg-sm.jpg') no-repeat 50% 50%;
    background-size: cover;
  }
  &.home {
    background: #4c62b7 url('../images/banners/home-banner-sm.jpg') no-repeat 50% 50%;
    background-size: cover;
  }
  &.mature {
    background: #4c62b7 url('../images/banners/mature-banner-bg-sm.jpg') no-repeat 50% 50%;
    background-size: cover;
  }
  &.aaa-pilot {
    background: #4c62b7 url('../images/banners/aaa-pilot-banner-sm.jpg') no-repeat 50% 50%;
    background-size: cover;
  }
  &.teendriver {
    background: #4c62b7 url('../images/banners/ftd-banner-bg-sm.jpg') no-repeat 50% 25%;
    background-size: cover;
  }
  &.geico {
    background: #fff url('../images/geico/banners/banner-bg-sm.jpg') no-repeat 50% 25%;
    background-size: cover;
    #banner {
      h2 {
        text-shadow: 0 0 0;
      }
      .details {
        text-shadow: 0 0 0;
      }
    }
  }
  .dropdown-list {
    display: inline-block;
    margin: 0;
    vertical-align: middle;
  }
}

// Icon Wrap
#icons-wrap {
  span {
    line-height: 1.3em;
    display: inline-block;
    vertical-align: middle;
  }
  .icon.bottom,
  .icon.top {
    border-bottom: 1px dotted #ccc;
  }
  .border-icon {
    border-bottom: 1px solid $theme-secondary;
  }
  .icon.min-125 {
    min-width: 125px;
  }
}

// Large styled dropdown
.dropdown-list {
  width: 100%;
  max-width: 300px;
  span.dropdown.cta-dropdown {
    min-width: 170px;
    padding: 16px;
    padding-bottom: 14px;
    color: #fff;
    font-weight: 400;
    background: $cta-red;
    border: 0;
    width: 100%;
    height: auto;
    line-height: 1;
    font-size: 20px;
    text-align: left;
    font-family: 'Fjalla One', 'Open Sans', 'Trebuchet MS', Verdana, Arial, Helvetica, sans-serif;
    text-transform: uppercase;
    text-shadow: 0 0 1px rgba(0, 0, 0, 0.75);
    & + .drop {
      top: 50px;
      &.small-cta {
        top: 42px;
      }
    }
    &.small {
      padding: 12px 16px;
      font-size: 18px;
      & + .drop {
        top: 42px;
      }
    }
    &:hover,
    &.is-active {
      background: darken($cta-red, 5%);
    }
    &.outline {
      background: #fff !important;
      color: $cta-red;
      border: 1px solid $cta-red;
      text-shadow: none;
      &:hover,
      &:active {
        background: rgba($cta-red, 0.1) !important;
      }
      & + .drop {
        top: 52px;
      }
      &.small {
        & + .drop {
          top: 44px;
        }
      }
    }
  }
}

// Cards
.card {
  &.action {
    border-top: 3px solid #374785;
    position: relative;
    z-index: 10;
  }
  &.course-display {
    border-top: 3px solid #374785;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    transition: box-shadow 0.3s ease 0s;
    &:hover {
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
    }
  }
  &.small-price {
    border: 1px solid #ccc;
    border-radius: 5px;
    background: #626677;
    max-width: 250px;
  }
  hr {
    border-top: 1px solid rgba(0, 0, 0, 0.4);
  }
}

/* BLURB (Most popular and December Special) */
.sales-blurb {
  margin-top: -55px;
  position: absolute;
  width: 100%;
  transform: translate(-50%, 0);
  &.highlight,
  .highlight {
    color: $cta-blue;
    font-weight: normal;
    font-size: 21px;
    font-family: 'Fjalla One', Impact, 'Open Sans', Helvetica, Arial, Verdana, Tahoma, sans-serif;
  }
  &.special {
    margin-top: -75px;
  }
  .mt-n1 {
    margin-top: -0.25rem !important;
  }
}

/* RECORD TABLE */
#record-table {
  border: 1px solid #999;
  border-top: 5px solid #999;
  background: #fff;
  h2 {
    text-align: center;
    font-weight: bold;
    margin-top: 10px;
  }
  .record a {
    color: #555;
  }
  .line {
    padding: 15px;
    border-bottom: 1px dotted #66ccff;
    overflow: hidden;
    font-size: 0;
    &:first-child {
      border-top: 0px;
    }
    &:last-child {
      border-bottom: 0px;
    }
    &:hover {
      background: #e5f6ff;
    }
  }
  .record {
    padding: 0 10px;
    color: #555;
    font-size: 24px;
    font-weight: bold;
    width: 40%;
    padding: 0 10px;
    text-align: center;
  }
  .pricing {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    color: #555;
    width: 20%;
  }
  .link,
  .rates {
    text-align: center;
    font-size: 16px;
    width: 20%;
  }
  .link,
  .order {
    margin: 0 auto;
    margin-left: 0;
  }
  .order {
    text-align: center;
    margin-left: 20%;
    width: 20%;
  }
  .link {
    margin-left: -40%;
  }
  .btn {
    width: 100%;
    max-width: 170px;
    height: 35px;
    line-height: 19px;
    font-size: 16px;
  }
}
#record-table .record,
#record-table .pricing,
#record-table .link,
#record-table .rates,
#record-table .order {
  display: inline-block;
  *display: inline;
  vertical-align: middle;
  margin: 0;
  width: 100%;
}

// Etc
#practice-test {
  ul {
    list-style-type: none;
    padding-left: 0;
    input {
      margin-right: 10px;
    }
  }
  .alert {
    p {
      margin-bottom: 0;
    }
    &.green {
      background-color: #e9ffdb;
      border: 1px solid #6cb142;
      color: #4e8c28;
    }
    &.red {
      background-color: #ffeeee;
      border: 1px solid #ffabab;
      color: #cc0000;
    }
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
  &:before {
    display: block;
    content: '';
    padding-top: 56.25%;
  }
  iframe {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
}
.records-wrap-notice {
  background-color: #35b4cf;
  font-weight: 600;
}
.coupon {
  border: 2px dashed #000045;
  background-color: #f0f2f4;
  font-size: 1.3em;
}
.tx-defensive blockquote {
  margin: 10px 0 0 0;
  font:
    normal 30px / 40px Georgia,
    'Trebuchet MS',
    Verdana,
    Tahoma,
    Helvetica,
    Arial,
    sans-serif;
  color: #000;
  font-style: italic;
  border: 0;
}

// Tool styling
.fw-semibold {
  font-weight: 600;
}
.border-dotted {
  border-bottom: 1px dotted;
  padding-bottom: 2px;
}
.border-callout {
  border-color: #bbc5ef !important;
}
.alert.alert-warning h1,
.alert.alert-warning h2,
.alert.alert-warning h3,
.alert.alert-warning h4 {
  color: var(--bs-warning-text-emphasis);
}
.mw-150 {
  min-width: 150px;
}
.mw-250 {
  min-width: 250px;
}
.mxw-350 {
  max-width: 350px;
}
.mxw-550 {
  max-width: 550px;
}
.btn-width-xl {
  width: 280px;
  max-width: 280px;
}
.table-wrap {
  overflow-x: scroll;
}
.form-control.is-invalid,
.was-validated .form-control:invalid {
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) !important;
}
.z-10 {
  position: relative;
  z-index: 10;
}

/* AAA Pilot */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.sticky {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  animation: fadeIn 0.15s;
  z-index: 99;
}
.aaa-pilot {
  .dropdown-list {
    width: 100%;
    max-width: 230px;
  }
  .cta-dropdown {
    min-width: 170px !important;
  }
  .btn-width-min {
    min-width: 140px;
  }
}

/* ----------------------------------------------------------------------------
    Page Media Queries
----------------------------------------------------------------------------- */
// Contenful inline images
.post-inline-img {
  max-width: 100%;
}
@media (min-width: 576px) {
  .post-inline-img.aligned {
    max-width: 35%;
  }
}

/* ----------------------------------------------------------------------------
    Page Media Queries
----------------------------------------------------------------------------- */
// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
  #icons-wrap {
    .icon.bottom {
      border-bottom: 0;
    }
  }
  .btn-width {
    width: 220px;
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  // this adds an extra padding to fluid containers
  [class*='container-'] {
    padding-right: var(--bs-gutter-x, 1.5rem);
    padding-left: var(--bs-gutter-x, 1.5rem);
  }
  .container-fluid.but-fixed {
    padding-left: 30px;
    padding-right: 30px;
  }
  #banner-wrap {
    &.landing {
      // landing used for all major redesigned banners
      background: #4c62b7 url('../images/banners/banner-bg.jpg') no-repeat 50% 50%;
      background-size: cover;
      #banner {
        .details {
          font-size: 1.1em;
        }
      }
    }
    &.boating {
      background: #4c62b7 url('../images/banners/boating-banner-bg.jpg') no-repeat 50% 50%;
      background-size: cover;
    }
    &.defensive {
      background: #4c62b7 url('../images/banners/defensive-banner-bg.jpg') no-repeat 50% 50%;
      background-size: cover;
    }
    &.bus {
      background: #4c62b7 url('../images/banners/bus-banner-bg.jpg') no-repeat 50% 50%;
      background-size: cover;
    }
    &.drivered {
      background: #4c62b7 url('../images/banners/drivered-banner-bg.jpg') no-repeat 50% 50%;
      background-size: cover;
    }
    &.hazmat {
      background: #4c62b7 url('../images/banners/hazmat-banner-bg.jpg') no-repeat 50% 50%;
      background-size: cover;
    }
    &.mature {
      background: #4c62b7 url('../images/banners/mature-banner-bg.jpg') no-repeat 50% 50%;
      background-size: cover;
    }
    &.aaa-pilot {
      background: #4c62b7 url('../images/banners/aaa-pilot-banner-lg.jpg') no-repeat 50% 50%;
      background-size: cover;
    }
    &.teendriver {
      background: #4c62b7 url('../images/banners/ftd-banner-bg.jpg') no-repeat 100% 50%;
      background-size: cover;
    }
    &.geico {
      background: #fff url('../images/geico/banners/banner-bg.jpg') no-repeat 50% 50%;
      background-size: cover;
    }
  }
  .border-md-end {
    border-right: 1px dotted #ccc;
    padding-right: 30px;
  }
  #record-table {
    .record {
      width: 40%;
    }
    .pricing {
      width: 20%;
    }
    .order {
      margin-left: 20% !important;
      width: 20%;
    }
    .link {
      margin-left: -40% !important;
      width: 20%;
    }
    .rates {
      width: 20%;
    }
  }
  .aaa-pilot {
    .cta-dropdown {
      min-width: 220px !important;
    }
  }
}

// roughtly 900px/16px
@media only screen and (min-width: 56.25em) {
  #footer p.trustmark {
    display: block;
  }
  #header-wrap {
    border-top: 3px solid $theme-primary;
  }
  #header a.logo {
    span.small {
      font-size: 15px;
    }
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  // this reverts the extra padding added to fluid containers
  [class*='container-'] {
    padding-right: var(--bs-gutter-x, 0.75rem);
    padding-left: var(--bs-gutter-x, 0.75rem);
  }
  #header a.logo {
    margin-top: 15px;
    margin-bottom: 15px;
    width: 50%;
    img {
      max-width: 350px;
    }
  }
  #banner-wrap {
    &.home {
      background: #fff url('../images/banners/home-banner-md.jpg') no-repeat 110% 50%;
      background-size: contain;
    }
    &.geico {
      #banner {
        background: url('/images/geico/geico-gecko.png') no-repeat 0 bottom;
        &.geico-home {
          background-size: 18%;
        }
      }
    }
  }
  #overlay-banner {
    background: linear-gradient(110deg, rgb(255, 255, 255) 0, rgb(255, 255, 255) 50%, rgba(0, 0, 0, 0) 50.1%);
  }
  #icons-wrap {
    .icon {
      &.top {
        border: 0;
      }
      &.border-blue-end {
        border-right: 2px solid $theme-primary;
      }
    }
    .border-icon {
      border-right: 1px solid $theme-secondary;
      border-bottom: 0;
    }
    .icon.min-125 {
      min-width: auto;
    }
  }
  .tx-defensive {
    #content {
      background: transparent url('../images/banners/tx-defensive.png') no-repeat 0 bottom;
    }
    blockquote {
      margin: 25px 0 0 0;
    }
    ul {
      font-size: 1.1em;
    }
  }
  .border-lg-0 {
    border: 0 !important;
  }
}

// Custom size just for home banner
@media (min-width: 1100px) {
  #banner-wrap {
    &.home {
      background-position: 100% 50%;
    }
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  #banner-wrap {
    &.geico {
      #banner {
        background-position: 5% bottom;
      }
    }
  }
  .table-wrap {
    overflow-x: hidden;
  }
}

@media (min-width: 1300px) {
  #banner-wrap {
    &.home {
      background: #fff url('../images/banners/home-banner-lg.jpg') no-repeat calc(50% + 360px) 50%;
      background-size: contain;
    }
    &.driving-records {
      #banner {
        background: #fff url('../images/banners/banner-bg-dr.jpg') no-repeat 0 0;
        background-size: contain;
        .details {
          font-size: 1.1em;
        }
      }
    }
    &.geico {
      #banner {
        background-position: 7% bottom;
      }
    }
  }
}
